import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "twin.macro"
import { Parallax } from 'react-scroll-parallax';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footer_img: file(relativePath: { eq: "ph_footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  return(
    <footer tw="relative h-screen w-full mt-20 lg:mt-40">
      <div tw="w-full h-screen overflow-hidden absolute">
        <Parallax y={[-20, 20]} tagOuter="figure">
        <Img
              fluid={data.footer_img.childImageSharp.fluid}
              alt=""
              tw="h-screen w-full"
            />
        </Parallax>
      </div>
      <div tw="absolute flex h-full w-full bg-black bg-opacity-10"></div>
      <div tw="absolute flex h-full w-full justify-center items-center">
        <small tw="font-en text-gold text-lg tracking-wider">© Yumiko Kinjo</small>
      </div>
    </footer>
  )
}

export default Footer