/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "typeface-abhaya-libre"
import '../css/global.scss'
import "twin.macro"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "typeface-abhaya-libre"

const Layout = ({ children , workPage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [showTotopClass, setshowTotopClass] = useState('is-hidden toTop-wrap')
  useEffect(() => {
    function handleScroll() {
      window.pageYOffset > 200 ? setshowTotopClass('is-show toTop-wrap') : setshowTotopClass('is-hidden toTop-wrap')
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
    <ParallaxProvider>
      {
        !workPage ? 
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> : 
          <header tw="fixed top-4 right-4 md:right-8 z-30">
            <Link to="/" className="workToHome">
              <span className="tohomeIcon"></span>
            </Link>
            <span className="tohomeText" tw="font-en">home</span>
          </header>
      }
      
      <main id="top">{children}</main>
      {
        !workPage ? <Footer/> : <></>
      }
      <button
        tw="
          fixed
          bottom-0
          right-0
          w-screen
          h-auto
          z-20
          bg-black_g
          text-gold
          text-center
          pb-2
          cursor-pointer
          transition-opacity
          duration-500
          ease-in
          outline-none
        "
        className={showTotopClass}
        onClick={() => {scrollTo('#top')}}
      >
        <span tw="border border-gold block w-4 h-4 mx-auto transform rotate-45 mt-4" className="totpo-button"></span>
        <span tw="block -mt-2 font-en">to top</span>
        
      </button>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
