import PropTypes from "prop-types"
import React, { useState } from 'react'
import "twin.macro"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = ({ siteTitle }) => {
  const [ menuOpened, setMenuOpened ] = useState('is_close')

  function toggleMenu () {
    menuOpened === 'is_close' ? setMenuOpened('is_open') : setMenuOpened('is_close')
  }
  return(
    <header
      className={ menuOpened }
      tw="
        fixed
        z-50
      "
    >
      <button className="humburger" onClick={ () => toggleMenu() }>
        <span></span>
      </button>
      <div
        className="menu_wrap"
        tw="
          flex
          flex-col
          sm:flex-row
          sm:justify-between
          bg-black_g
          w-screen
          h-screen
          text-gold
          sm:p-10
          md:p-20
          lg:px-28
        "
      >
        <div tw="
          flex 
          flex-col 
          justify-center
          sm:justify-center
          h-1/2 
          sm:h-full 
          sm:w-auto 
        ">
          <h1>
            <span tw="block font-en text-center sm:text-left text-2xl md:text-5xl font-normal">Yumiko KINJO</span>
            <span tw="block text-lg text-center sm:text-left text-sm">金城有美子</span>
          </h1>
          <nav tw="mt-4">
            <ul tw="m-0 flex justify-center sm:justify-start">
              <li>
                <a href="https://www.instagram.com/kinjoyumiko/" target="_blank" rel="noopener noreferrer" title="instagram">
                  <svg xmlns="http://www.w3.org/2000/svg" width="27.08" height="27.074">
                    <desc>instagram</desc>
                    <path d="M13.543 6.596c-3.833 0-6.941 3.108-6.941 6.941s3.108 6.941 6.941 6.941 6.941-3.108 6.941-6.941a6.93 6.93 0 0 0-6.941-6.941zm0 11.454c-2.492 0-4.513-2.021-4.513-4.513s2.021-4.513 4.513-4.513 4.513 2.021 4.513 4.513a4.52 4.52 0 0 1-4.513 4.513zm8.845-11.738a1.62 1.62 0 0 1-1.619 1.619 1.62 1.62 0 0 1-1.619-1.619 1.62 1.62 0 0 1 1.619-1.619c.43-.001.842.169 1.146.473s.474.716.473 1.146zm4.6 1.643a8.01 8.01 0 0 0-2.187-5.673C23.267.84 21.23.055 19.125.095c-2.235-.127-8.935-.127-11.17 0-2.103-.042-4.139.74-5.673 2.18A8.04 8.04 0 0 0 .095 7.949c-.127 2.235-.127 8.935 0 11.17a8.01 8.01 0 0 0 2.187 5.673c1.534 1.439 3.57 2.222 5.673 2.183 2.235.127 8.935.127 11.17 0a8.01 8.01 0 0 0 5.673-2.187c1.441-1.533 2.226-3.569 2.187-5.673.127-2.231.127-8.925 0-11.16zm-2.891 13.563a4.57 4.57 0 0 1-2.574 2.574c-1.782.707-6.011.544-7.981.544s-6.2.157-7.981-.544a4.57 4.57 0 0 1-2.574-2.574c-.707-1.782-.544-6.011-.544-7.981s-.157-6.2.544-7.981a4.57 4.57 0 0 1 2.574-2.574c1.782-.707 6.011-.544 7.981-.544s6.2-.157 7.981.544a4.57 4.57 0 0 1 2.574 2.574c.707 1.782.544 6.011.544 7.981s.163 6.205-.544 7.981z" fill="#d8bc89"/>
                  </svg>
                </a>
              </li>
              <li tw="ml-8">
                <a href="https://www.facebook.com/yumiko.kinjo.1" target="_blank" rel="noopener noreferrer" title="facebook">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.925" height="26">
                    <desc>facebook</desc>
                    <path d="M13.01 14.625l.722-4.705H9.22V6.866c-.064-.712.2-1.414.716-1.908s1.229-.728 1.937-.634h2.053V.318A25.03 25.03 0 0 0 10.282 0C6.564 0 4.133 2.254 4.133 6.333V9.92H0v4.705h4.133V26H9.22V14.625z" fill="#d8bc89"/>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div tw="
          flex 
          items-center 
          sm:items-end 
          justify-center 
          sm:justify-start
          h-1/2 
          sm:h-full 
          sm:w-auto 
          text-center 
          sm:text-left
        ">
          <nav>
            <ul tw="m-0">
              <li>
                <button onClick={() => {scrollTo('#profile'); toggleMenu()}} tw="sm:text-left">
                  <span tw="block font-en text-4xl sm:text-5xl lg:text-7xl">Profile</span>
                  <span tw="block text-sm">プロフィール</span>
                </button>
              </li>
              <li>
                <button onClick={() => {scrollTo('#works'); toggleMenu()}} tw="sm:text-left mt-4">
                  <span tw="block font-en text-4xl sm:text-5xl lg:text-7xl">Works</span>
                  <span tw="block text-sm ">作品</span>
                </button>
              </li>
              <li>
                <button onClick={() => {scrollTo('#contact'); toggleMenu()}} tw="sm:text-left mt-4">
                  <span tw="block font-en text-4xl sm:text-5xl lg:text-7xl">Contact</span>
                  <span tw="block text-sm ">お問い合わせ</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
