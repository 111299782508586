import React from 'react'
import "twin.macro"

const ContentGrid = ({ children }) => {
  return(
    <div tw="
      grid 
      grid-cols-content-4 
      sm:grid-cols-content-8 
      md:grid-cols-content-12
    ">
    { children }
    </div>
  )
}

export default ContentGrid