/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, workPage, pagePath, thumbPath}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const bodyClass = workPage ? 'font-body woks-page' : 'font-body other-page'

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const url = pagePath ? `${site.siteMetadata.siteUrl}${pagePath}` : site.siteMetadata?.siteUrl
  const imgUrl = thumbPath ? `${thumbPath}?w=1200` : `${site.siteMetadata.siteUrl}/yumikokinjo.jpg`
  const setTitle= title==='Home' ? defaultTitle : `${title} | ${defaultTitle}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: bodyClass
      }}

      title={setTitle}
      

      //titleTemplate=  {defaultTitle ? `%s | ${defaultTitle}` : null}
      
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: setTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: imgUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: setTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content:
            'LDZ_Xxkt6rXN846s1MqT23jfnX9CvPVxLViWNxWW5Eg',
        }
      ].concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: url ,
        },
        {
          rel: `stylesheet`,
          type: `text/css`,
          charset: `UTF-8`,
          href: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css`,
        },
        {
          rel: `stylesheet`,
          type: `text/css`,
          charset: `UTF-8`,
          href: `https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css`,
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: `陶芸作家、金城有美子のサイトです。`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
